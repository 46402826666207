import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

export const microsoftConfig: OpenIdConfiguration = {
  configId: 'auth-config-microsoft',
  authority: 'https://login.microsoftonline.com/organizations/v2.0',
  clientId: environment.microsoft.clientId,
  disablePkce: true,
  redirectUrl: `${window.location.origin}${environment.microsoft.redirectPath}`,
  responseType: 'code',
  scope:
    'openid profile email offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Sites.Manage.All',
  logLevel: LogLevel.None,
};
