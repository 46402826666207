import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TokenSetParameters } from 'openid-client';

import { IntegrationType } from '@site-mate/sitemate-flowsite-shared';

import { FlowsiteApiHttpClient } from '../http/clients/api.client';

export type TokenExchangeParams = {
  integrationType: IntegrationType;
  code: string;
  redirect_uri: string;
};

export interface IExternalAuthState {
  workspaceId: string;
  connectionId?: string;
}

@Injectable({ providedIn: 'root' })
export class ExternalAuthService {
  private readonly api = inject(FlowsiteApiHttpClient);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly stateKey = 'external-auth-redirect-state';

  /**
   * Checks if the user is authenticated, this method initiates the authorization flow if the user is not authenticated.
   * @param url the URL to perform the authorization on the behalf of the user
   * @param configId the configuration ID to use for the authorization
   * @returns the LoginResponse observable
   */
  public checkAuth(url: string, configId: string) {
    return this.oidcSecurityService.checkAuth(url, configId);
  }

  public getAuthenticationResult(configId: string) {
    return this.oidcSecurityService.getAuthenticationResult(configId);
  }

  public getAccessTokenPayload(configId: string) {
    return this.oidcSecurityService.getPayloadFromAccessToken(false, configId);
  }

  public exchangeToken(params: TokenExchangeParams) {
    return this.api.post<TokenSetParameters>(
      `/integrations/${params.integrationType}/token`,
      {},
      { params: new HttpParams({ fromObject: params }) },
    );
  }

  /**
   * Initiates the authorization flow for the user.
   * @param configId the configuration ID to use for the authorization
   * @param workspaceId the workspaceId to know which workspace the user is trying to access
   */
  public login(configId: string, state: IExternalAuthState) {
    sessionStorage.setItem(this.stateKey, JSON.stringify(state));
    return this.oidcSecurityService.authorize(configId);
  }

  public getState() {
    const value = sessionStorage.getItem(this.stateKey);
    return value ? (JSON.parse(value) as IExternalAuthState) : null;
  }

  public clearState() {
    sessionStorage.removeItem(this.stateKey);
  }
}
