import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';

export type ObservableWithStatus<T> = Observable<{ loading: boolean; value?: T; error?: any }>;

/**
 * Taken from: https://medium.com/angular-in-depth/angular-show-loading-indicator-when-obs-async-is-not-yet-resolved-9d8e5497dd8
 * Using the basic version of the pipe, which is intended to be used for one-time observables (like HTTP requests).
 */
@Pipe({
  name: 'withLoading',
  standalone: true,
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(obs: Observable<T>): ObservableWithStatus<T> {
    return obs.pipe(
      map((value) => ({ loading: false, value })),
      startWith({ loading: true }),
      catchError((error) => of({ loading: false, error })),
    );
  }
}
