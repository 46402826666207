import { OpenIdConfiguration } from 'angular-auth-oidc-client';

import { IntegrationType, Maybe } from '@site-mate/sitemate-flowsite-shared';

import { dashpivotConfig } from './dashpivot.config';
import { microsoftConfig } from './microsoft.config';
import { quickbooksConfig } from './quickbooks.config';
import { xeroConfig } from './xero.config';

export const OAuthCallbacks = new Map<IntegrationType, { settings: OpenIdConfiguration }>([
  [IntegrationType.XERO, { settings: xeroConfig }],
  [IntegrationType.DASHPIVOT, { settings: dashpivotConfig }],
  [IntegrationType.QUICKBOOKS, { settings: quickbooksConfig }],
  [IntegrationType.MICROSOFT, { settings: microsoftConfig }],
]);

export const getOAuthConfig = (type: IntegrationType): Maybe<OpenIdConfiguration> =>
  OAuthCallbacks.get(type)?.settings;
